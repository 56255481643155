import React, { useContext, useEffect, useState } from "react";
import Face from "../../assets/img/face.jpg";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { BsThreeDots } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosSettings,
  IoMdTrash,
} from "react-icons/io";
import { BiSolidUserCircle } from "react-icons/bi";
import { LuLogOut } from "react-icons/lu";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../utils/userContext";
import axios from "axios";
import {
  isToday,
  isYesterday,
  startOfToday,
  startOfYesterday,
  startOfWeek,
  format,
  startOfMonth,
} from "date-fns";
import { t } from "i18next";

const SideBar = (props) => {
  const { logout, user, isAuthenticated } = useAuth0();
  const { user: userBack } = useContext(UserContext);
  const token = window.localStorage.getItem("token_user");
  const token_refresh = window.localStorage.getItem("refresh_token_user");
  const [loadingDelete, setLoadingDelete] = useState(false);
  let { assistantId } = useParams();

  const [historial, setHistorial] = useState({
    today: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
  });
  const [loading, setLoading] = useState(true);

  const hideSide = () => {
    props.setIsSideOpen(false);
    props.sideState("hide");
  };

  const showOverlaySettings = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.add("fixed");
    overlay.classList.remove("hidden");
  };

  // const fetchHistorial = async () => {
  //   try {
  //     const today = format(new Date(), "yyyy-MM-dd");
  //     const yesterday = format(startOfYesterday(), "yyyy-MM-dd");
  //     const weekStart = format(
  //       startOfWeek(new Date(), { weekStartsOn: 1 }),
  //       "yyyy-MM-dd"
  //     );
  //     const monthStart = format(startOfMonth(new Date()), "yyyy-MM-dd");

  //     const headers = {
  //       headers: {
  //         "X-API-KEY": process.env.REACT_APP_X_APIKEY,
  //         Authorization: "Bearer " + token,
  //       },
  //     };

  //     const [
  //       todayResponse,
  //       yesterdayResponse,
  //       thisWeekResponse,
  //       thisMonthResponse,
  //     ] = await Promise.all([
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_FILTER_HISTORIAL_DEVELOPMENT}?created_at__gte=${today}&user_fk=${userBack.id}`,
  //           headers
  //         )
  //         .catch((err) => {
  //           if (err.response.data.code === "token_not_valid") {
  //             let data = {
  //               refresh: token_refresh,
  //             };
  //             axios
  //               .post(process.env.REACT_APP_REFRESH_TOKEN_DEVELOPMENT, data, {
  //                 headers: {
  //                   "X-API-KEY": process.env.REACT_APP_X_APIKEY,
  //                 },
  //               })
  //               .then((res) => {
  //                 window.localStorage.setItem("token_user", res.data.access);
  //               });
  //           }
  //         }),
  //       axios.get(
  //         `${process.env.REACT_APP_FILTER_HISTORIAL_DEVELOPMENT}?created_at__gte=${yesterday}&created_at__lte=${today}&user_fk=${userBack.id}`,
  //         headers
  //       ),
  //       axios.get(
  //         `${process.env.REACT_APP_FILTER_HISTORIAL_DEVELOPMENT}?created_at__gte=${weekStart}&user_fk=${userBack.id}`,
  //         headers
  //       ),
  //       axios.get(
  //         `${process.env.REACT_APP_FILTER_HISTORIAL_DEVELOPMENT}?created_at__gte=${monthStart}&user_fk=${userBack.id}`,
  //         headers
  //       ),
  //     ]);

  //     const combinedData = [
  //       ...todayResponse.data,
  //       ...yesterdayResponse.data,
  //       ...thisWeekResponse.data,
  //       ...thisMonthResponse.data,
  //     ];

  //     const uniqueData = new Map();

  //     combinedData.forEach((item) => {
  //       if (
  //         !uniqueData.has(item.thread_id) ||
  //         new Date(uniqueData.get(item.thread_id).created_at) <
  //           new Date(item.created_at)
  //       ) {
  //         uniqueData.set(item.thread_id, item);
  //       }
  //     });

  //     const categorizedData = {
  //       today: [],
  //       yesterday: [],
  //       thisWeek: [],
  //       thisMonth: [],
  //     };

  //     uniqueData.forEach((item) => {
  //       const itemDate = format(new Date(item.created_at), "yyyy-MM-dd");
  //       if (itemDate === today) {
  //         categorizedData.today.push(item);
  //       } else if (itemDate === yesterday) {
  //         categorizedData.yesterday.push(item);
  //       } else if (new Date(item.created_at) >= new Date(weekStart)) {
  //         categorizedData.thisWeek.push(item);
  //       } else if (new Date(item.created_at) >= new Date(monthStart)) {
  //         categorizedData.thisMonth.push(item);
  //       }
  //     });

  //     setHistorial(categorizedData);
  //     setLoadingDelete(false);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching historial:", error);
  //     setLoading(false);
  //   }
  // };

  const fetchHistorial = async () => {
    try {
      const headers = {
        headers: {
          "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          Authorization: "Bearer " + token,
        },
      };
  
      // Realiza una única solicitud para obtener todo el historial del usuario.
      const response = await axios.get(
        `${process.env.REACT_APP_FILTER_HISTORIAL}?user_fk=${userBack.id}`,
        headers
      );
  
      const allData = response.data;
  
      // Filtrar elementos únicos usando assistant_id como clave.
      const uniqueData = new Map();
  
      allData.forEach((item) => {
        if (
          !uniqueData.has(item.assistant_fk.assistant_id) ||
          new Date(uniqueData.get(item.assistant_fk.assistant_id).created_at) < new Date(item.created_at)
        ) {
          uniqueData.set(item.assistant_fk.assistant_id, item);
        }
      });
  
      const categorizedData = {
        today: [],
        yesterday: [],
        thisWeek: [],
        thisMonth: [],
      };
  
      const today = format(new Date(), "yyyy-MM-dd");
      const yesterday = format(startOfYesterday(), "yyyy-MM-dd");
      const weekStart = format(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        "yyyy-MM-dd"
      );
      const monthStart = format(startOfMonth(new Date()), "yyyy-MM-dd");
  
      uniqueData.forEach((item) => {
        const itemDate = format(new Date(item.created_at), "yyyy-MM-dd");
        if (itemDate === today) {
          categorizedData.today.push(item);
        } else if (itemDate === yesterday) {
          categorizedData.yesterday.push(item);
        } else if (new Date(item.created_at) >= new Date(weekStart)) {
          categorizedData.thisWeek.push(item);
        } else if (new Date(item.created_at) >= new Date(monthStart)) {
          categorizedData.thisMonth.push(item);
        }
      });
  
      setHistorial(categorizedData);
      setLoadingDelete(false);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching historial:", error);
      setLoading(false);
    }
  };
  const deleteChat = (idTrhead) => {
    setLoadingDelete(true);
    axios
      .delete(
        process.env.REACT_APP_FILTER_HISTORIAL + idTrhead + "/",
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        fetchHistorial();
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const renderHistorialItems = (items) => {
  //   return items.map((his, index) =>(
  //     <div
  //       key={index}
  //       className={`flex justify-between group cursor-pointer items-center relative ${assistantId === his.assistant_fk.assistant_id && 'bg-bunker-800'} hover:bg-bunker-800 dark:bg-[#ECECEC] dark:hover:opacity-100 rounded-md p-2 gap-2 custom-transition mb-2`}
  //     >
  //       <a
  //         href={`/chat/assistant/${his.assistant_fk.assistant_id}`}
  //         className="flex items-center gap-2"
  //       >
  //         <img
  //           src={his.assistant_fk.image}
  //           alt=""
  //           className="w-[32px] h-[32px] rounded-full object-cover dark:shadow-sm dark:shadow-[#eeeeee]"
  //         />
  //         <span className="text-sm text-bunker-50 dark:text-black line-clamp-1">
  //           {his.assistant_fk.name}
  //         </span>
  //       </a>
  //       <button className=" p-1 rounded-full opacity-0 group-hover:opacity-100 hover:bg-[#1F1F23] custom-transition flex justify-center items-center cursor-pointer">
  //         {loadingDelete ? (
  //           <span className="loading loading-spinner loading-xs text-[#fafafa]"></span>
  //         ) : (
  //           <IoMdTrash
  //             className="text-[#fafafa] dark:group-hover:text-[#fafafa] text-base"
  //             onClick={() => {
  //               deleteChat(his.thread_id);
  //             }}
  //           />
  //         )}
  //       </button>
  //     </div>
  //   ));
  // };

  const renderHistorialItems = (items) => {
    return items.map((his, index) => (
      <div
        key={index}
        className={`flex justify-between group cursor-pointer items-center relative ${
          assistantId === his.assistant_fk.assistant_id && "bg-bunker-800"
        } hover:bg-bunker-800 dark:bg-[#ECECEC] dark:hover:opacity-100 rounded-md p-2 gap-2 custom-transition mb-2`}
      >
        <a
          href={`/chat/assistant/${his.assistant_fk.assistant_id}`}
          className="flex items-center gap-2"
        >
          <img
            src={his.assistant_fk.image}
            alt=""
            className="w-[32px] h-[32px] rounded-full object-cover dark:shadow-sm dark:shadow-[#eeeeee]"
          />
          <span className="text-sm text-bunker-50 dark:text-black line-clamp-1">
            {his.assistant_fk.name}
          </span>
        </a>
        <button className="p-1 rounded-full opacity-0 group-hover:opacity-100 hover:bg-[#1F1F23] custom-transition flex justify-center items-center cursor-pointer">
          {loadingDelete ? (
            <span className="loading loading-spinner loading-xs text-[#fafafa]"></span>
          ) : (
            <IoMdTrash
              className="text-[#fafafa] dark:group-hover:text-[#fafafa] text-base"
              onClick={() => {
                deleteChat(his.thread_id);
              }}
            />
          )}
        </button>
      </div>
    ));
  };

  // useEffect(() => {
  //   if (userBack != null) {
  //     if(props.historial == null){
  //       fetchHistorial();
  //     }else if(props.historial){
  //       fetchHistorial();
  //     }
  //   }
  // }, [userBack, props.historial]);

  useEffect(() => {
    if (userBack != null) {
        fetchHistorial();
    }
  }, [userBack]);

  return (
    <div
      className={`side h-[100vh] flex flex-col justify-between overflow-hidden w-full transition-all duration-300 ${
        props.isSideOpen
          ? "translate-x-0 max-w-64"
          : "-translate-x-full max-w-0"
      } overflow-y-scroll border-r border-pr-400 dark:border-[#F9F9F9] bg-pr-800 dark:bg-white p-2 py-3 absolute left-0 z-10 custom-transition md:static md:left-auto md:z-0`}
    >
      <div>
        <div className="overflow-hidden flex flex-col  w-full">
          <div className="w-full flex justify-between p-2 py-3 items-center">
            <h1 className="text-base text-bunker-50 dark:text-[#7D7D7D] font-bold">
              EnglishCode.ai
            </h1>
            {props.isSideOpen && (
              <div
                onClick={hideSide}
                className="group p-2 rounded-full hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center cursor-pointer"
              >
                <FaAngleDoubleLeft className="text-bunker-800 dark:text-[#7D7D7D] " />
              </div>
            )}
          </div>
          <div className="flex flex-col w-full pl-2 pt-2 md:pt-5 gap-5">
            <div className="relative h-10 mr-4 ">
              <div className="absolute z-40 flex gap-2 w-full text-bunker-50 dark:text-[#7D7D7D] border-spacing-1 border-divider flex-row self-center items-center rounded-spacing-xs bg-pr-600 dark:bg-white dark:border-[#202024] p-2 pl-3 placeholder:text-[#7C7C87] rounded-lg">
                <button className=" flex justify-center items-center">
                  <FiSearch className="text-bunker-50 dark:text-[#7D7D7D]" />
                </button>
                <input
                  type="text"
                  placeholder={t("search-profession")}
                  className="outline-none border-none w-[90%] bg-transparent text-bunker-50 dark:text-[#7D7D7D] text-sm"
                />
              </div>
            </div>

            <div className="w-full flex justify-center items-center">
              <Link
                to="/"
                className="w-full rounded-lg text-bunker-50 dark:text-[#7D7D7D] hover:bg-bunker-800 dark:hover:bg-[#f9f9f9] custom-transition flex items-center justify-start gap-4 p-3"
              >
                <AiFillHome className="text-base text-[#a3a3a3]" /> {t("home")}
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full min-h-[70%] relative flex flex-col gap-1 overflow-x-auto p-2 pt-3">
          <ul className="w-full flex flex-col gap-0.5 outline-none">
            {historial.today.length > 0 && (
              <li className="relative mb-2">
                <span className="pl-1 text-xs text-[#9999A3]">
                  {t("today")}
                </span>
                <ul className="pt-1">
                  {renderHistorialItems(historial.today)}
                </ul>
              </li>
            )}
            {historial.yesterday.length > 0 && (
              <li className="relative mb-2">
                <span className="pl-1 text-xs text-[#9999A3]">
                  {t("yesterday")}
                </span>
                <ul className="pt-1">
                  {renderHistorialItems(historial.yesterday)}
                </ul>
              </li>
            )}
            {historial.thisWeek.length > 0 && (
              <li className="relative mb-2">
                <span className="pl-1 text-xs text-[#9999A3]">
                  {t("this-week")}
                </span>
                <ul className="pt-1">
                  {renderHistorialItems(historial.thisWeek)}
                </ul>
              </li>
            )}
            {historial.thisMonth.length > 0 && (
              <li className="relative mb-2">
                <span className="pl-1 text-xs text-[#9999A3]">
                  {t("this-month")}
                </span>
                <ul className="pt-1">
                  {renderHistorialItems(historial.thisMonth)}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="w-full">
        <span className="shrink-0 block bg-pr-400 h-[1px] w-full mb-3 "></span>
        <div className="w-full px-3 flex flex-col justify-between items-center gap-5">
          <div className="w-full flex justify-center items-center">
            <span className="text-center text-sm text-bunker-50 dark:text-[#7D7D7D]">
              {t("c-teilur-inc-founded-with-by-a-remote-team")}
            </span>
          </div>
          {isAuthenticated && (
            <div className="dropdown dropdown-top w-full">
              <div
                tabIndex={0}
                role="button"
                className="w-full flex justify-between items-center p-2 hover:bg-bunker-800 dark:hover:bg-[#f9f9f9] custom-transition rounded-xl cursor-pointer"
              >
                <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                  <img src={user.picture} alt="" className="w-full h-full" />
                </div>
                <p className="text-xs text-bunker-50 dark:text-[#7d7d7d] font-semibold truncate max-w-[60%]">
                  {userBack?.full_name}
                </p>
                <IoIosArrowDown className="text-[#7d7d7d] text-lg" />
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content menu rounded-xl z-[1] w-full p-2 shadow bg-pr-600 dark:bg-[#ECECEC]"
              >
                {/* <li className="w-full rounded-full ">
                  <button className="w-full flex justify-between items-center">
                    Profile <BiSolidUserCircle className="text-bunker-50 text-xl" />
                  </button>
                </li> */}
                <li className="w-full rounded-full ">
                  <button
                    onClick={() => {
                      showOverlaySettings();
                    }}
                    className="w-full flex justify-between items-center"
                  >
                    {t("settings")}{" "}
                    <IoIosSettings className="text-bunker-50 dark:text-[#7d7d7d] text-xl" />
                  </button>
                </li>
                <li className="w-full rounded-full ">
                  <button
                    onClick={() =>
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    }
                    className="w-fullflex justify-between items-center"
                  >
                    {t("logout")}{" "}
                    <LuLogOut className="text-bunker-50 dark:text-[#7d7d7d] text-xl" />
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;

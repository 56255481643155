import React, { useContext, useState } from "react";
import { IoClose } from "react-icons/io5";
import FormUploadCV from "../UI/FormUploadCV";
import axios from "axios";
import { UserContext } from "../utils/userContext";
import { useAuth0 } from "@auth0/auth0-react";
import SplashCV from "../pages/SplashCV";
import { t } from "i18next";

const UploadCV = () => {
  const [dataResume, setDataResume] = useState();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false)

  const { user, setUser } = useContext(UserContext);
  const { logout } = useAuth0();
  const token = window.localStorage.getItem("token_user")


  // const showSplash = () => {
  //   const splash = document.querySelector(".overlay3");

  //   splash.classList.add("fixed");
  //   splash.classList.remove("hidden");
  // };
  const showModalInactive = () =>{
    const modalI = document.querySelector('.modalI')
    modalI.classList.add("flex")
    modalI.classList.remove("hidden")

  }

  const getUser = () => {
    axios
      .get(process.env.REACT_APP_URL_GET_USERS + user.id+"/", {
        headers: {
          "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          "Authorization": "Bearer "+token
        },
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        if(err.response.data.detail === "User is inactive"){
          showModalInactive()
        }
      });
  };

  const hideOverlayCV = () => {
    const overlay = document.querySelector(".overlay2");
    overlay.classList.remove("fixed");
    overlay.classList.add("hidden");
  };

  const validateFile = (file) => {
    // Validar el tipo de archivo (PDF)
    if (file.type !== "application/pdf") {
      setError("The file must be a PDF.");
      return false;
    }
    // Validar el tamaño del archivo (2MB)
    if (file.size > 2 * 1024 * 1024) {
      setError("The file is too large. The maximum size is 2MB.");
      return false;
    }
    return true;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const btnSubmit = document.querySelector(".submit");
    const file = e.dataTransfer.files[0];

    if (file) {
      if (validateFile(file)) {
        setDataResume(file);
        setFileName(file.name);
        setError(null);
        btnSubmit.style.display = "flex";
      } else {
        setDataResume(null);
        setFileName("");
        btnSubmit.style.display = "none";
      }
    }
  };

  const handleResume = (e) => {
    const btnSubmit = document.querySelector(".submit");
    const file = e.target.files[0];
    if (file) {
      if (validateFile(file)) {

        setDataResume(file);
        setFileName(file.name);
        setError(null);
        btnSubmit.style.display = "flex";
      } else {
        setDataResume(null);
        setFileName("");
        btnSubmit.style.display = "none";
      }
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    setDone(true)
    e.preventDefault();
    const formData = new FormData();

    formData.append("cv", dataResume);

    axios
      .post(process.env.REACT_APP_URL_CV + user.id + "/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          "Authorization": "Bearer "+token
        },
      })
      .then((res) => {
        getUser();
        hideOverlayCV();
        setDone(false)
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        setDone(false)
        if(err.response.data.detail === "User is inactive"){
          showModalInactive()
        }
      });
  };

  return (
    <>
    <div className="overlay2 w-full h-screen justify-center items-center fixed z-50 left-0 top-0 bottom-0 right-0">
      <div className="h-full w-full flex justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10">
        <div className="h-full md:h-[624px] md:min-w-[708px] flex bg-pr-600 rounded-xl relative">
          {/* <IoClose
            onClick={hideOverlayCV}
            className="absolute top-4 right-4 text-lg cursor-pointer"
          /> */}
          <div className="w-full h-full flex flex-col p-4 pt-10 justify-between items-center">
            <div>
              <h1 className="text-xl font-bold text-bunker-50 text-center">
                {t('welcome-to')} EnglishCode!
              </h1>
              <p className="text-sm text-bunker-50 text-center">
                {t('upload-your-cv-here-so-we-can-create-a-portal-designed-especially-for-you')}
              </p>
            </div>
            <FormUploadCV
              handleSubmit={handleSubmit}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              fileName={fileName}
              handleResume={handleResume}
              error={error}
              loading={loading}
            />
            <div className="w-full flex justify-end items-center">
              <button
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
                className="px-4 py-2 text-sm hover:bg-main-side bg-transparent rounded-full custom-transition"
              >
                {t('log-out')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {loading && <SplashCV loading={done}/>}
    </>
  );
};

export default UploadCV;

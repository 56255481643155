import React from "react";
import { PiPhoneFill } from "react-icons/pi";

const Call = (props) => {
  
  return (
    <div>
      {
        props.callActive === false && <button onClick={()=>{
            props.setCallActive(true)
            props.setMode("call")
            props.amplitude.track("Call start (Modo llamada)", {
              userId: props.user.id,
              userEmail: props.user.email,
              userName: props.user.full_name,
              buttonName: "Call",
              page: "Chat",
              assistant: props.assistant.name,
              assistantId: props.assistant.assistant_id,
              assistantRole: props.assistant.role,
            });
          }} id="call" className=" p-2 rounded-full border-[1px] border-bunker-600 dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer mr-2">
            <PiPhoneFill className="text-bunker-600 dark:text-[#7d7d7d] text-xl" />
          </button>
      }
    </div>
  );
};

export default Call;

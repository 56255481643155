import React, { useContext, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import QA from "../../assets/img/qa.png";
import axios from "axios";
import { UserContext } from "../utils/userContext";
import { useAuth0 } from "@auth0/auth0-react";

const CardSection1HomeActivities = ({ data, url, show }) => {
  const { user } = useContext(UserContext);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [gradientPosition, setGradientPosition] = useState({ x: 50, y: 50 });
  const [loading, setLoading] = useState(false)
  const token = window.localStorage.getItem("token_user")

  const showModalInactive = () =>{
    const modalI = document.querySelector('.modalI')
    modalI.classList.add("flex")
    modalI.classList.remove("hidden")

  }

  const redirectActivity = () => {
    if (isAuthenticated) {
      setLoading(true)
      let body = {
        parent_activity_id: data.parent_activity_id,
        vertical: "Quality Assurance Engineers",
        user_id: user.id,
      };
      axios
        .post(process.env.REACT_APP_URL_RANDOM_CODE, body, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            "Authorization": "Bearer "+token
          },
        })
        .then((res) => {
          setLoading(false)
          window.open(
            url + res.data.random_code + "/"+res.data.user_activity_id,
            "_blank"
          );
        })
        .catch((err)=>{
          setLoading(false)
          if(err.response.data.message === "No codes found"){
            show()
          }
          if(err.response.data.detail === "User is inactive"){
            showModalInactive()
          }
        })
    }
  };

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setGradientPosition({ x, y });
  };

  return (
    <div className="relative">
      {!isAuthenticated && (
        <div className="card absolute w-[280px] h-[280px] top-0 left-0 flex flex-col justify-center items-center gap-4 rounded-lg p-5 bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-5 z-50">
          <h2 className="text-sm text-white font-bold text-center">
            Join English
            <span className="text-bunker-500">Code</span> to access to
            activities
          </h2>
          <button
            onClick={() => loginWithRedirect()}
            className="custom-transition bg-bunker-600 rounded-full text-white hover:text-black hover:bg-[#e4e4e4] px-4 text-sm font-normal py-2"
          >
            Sign In
          </button>
        </div>
      )}
      <div
        onClick={redirectActivity}
        onMouseMove={handleMouseMove}
        style={{
          background: `radial-gradient(circle at ${gradientPosition.x}% ${gradientPosition.y}%, #124268, #040e17)`,
        }}
        className="card bg-gradient-to-t from-bunker-600 dark:from-[#ECECEC] cursor-pointer group p-4 flex flex-col items-start justify-between  w-[280px] h-[280px] custom-transition"
      >
        <div className="flex flex-col gap-2">
          <div className="w-full flex justify-start items-start gap-2">
            <img
              src={data.image}
              alt=""
              className="w-[48px] h-[48px] rounded-full object-cover"
            />
            <p className="text-bunker-50 dark:text-[#7d7d7d] text-base font-semibold line-clamp-2">
              {data.name}
            </p>
          </div>
          <div>
            <p className="text-bunker-50 dark:text-[#7d7d7d] text-base line-clamp-3">
              {data.description}
            </p>
          </div>
        </div>
        <div className="w-full flex justify-end items-center">
          {loading 
          ?<span className="loading loading-spinner loading-xs text-white"></span>
          :<FaArrowRightLong className="text-bunker-50 dark:text-[#7d7d7d] mr-5 group-hover:mr-0 custom-transition" />
          }
        </div>
      </div>
    </div>
  );
};

export default CardSection1HomeActivities;

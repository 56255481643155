import React, { useEffect, useState } from "react";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import Face from "../../assets/img/face.jpg";
import { GiSoundWaves } from "react-icons/gi";
import { BsThreeDots } from "react-icons/bs";
import { CiSun } from "react-icons/ci";
import { CiCloudMoon } from "react-icons/ci";
import Theme from "../UI/Theme";



const Header = (props) => {

  const [theme, setTheme] = useState("dark")

  const showSide = () => {
    props.setIsSideOpen(true)
    props.showSide("show");
  };

  const showSideRight = () => {
    props.setIsSideOpen2(true)
    props.showSideRight("show");
  };

  const changeTheme = () =>{
    setTheme(prevTheme =>  prevTheme === "dark" ? "light" : "dark")
  }

  useEffect(() => {
    const sun = document.querySelector(".sun")
    const moon = document.querySelector(".moon")

    if(theme === "light"){
      document.querySelector("html").classList.add("dark")
      sun.classList.add("opacity-0")
      sun.classList.remove("opacity-100")

      moon.classList.add("opacity-100")
      moon.classList.remove("opacity-0")

    }else{
      document.querySelector("html").classList.remove("dark")
      sun.classList.remove("opacity-0")
      sun.classList.add("opacity-100")

      moon.classList.remove("opacity-100")
      moon.classList.add("opacity-0")
    }
  }, [theme])
  

  return (
    <header className="w-full h-20 p-6 pl-16 md:px-16 bg-main2 dark:bg-white flex justify-between  items-center relative ">
      {!props.isSideOpen && (
        <button className="flex justify-center items-center absolute left-0 top-1/2 -translate-y-1/2">
          <div
            onClick={showSide}
            className="group p-2 ml-4 md:ml-0 rounded-full hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9]  custom-transition flex justify-center items-center cursor-pointer "
          >
            <HiOutlineMenuAlt1 className="text-[#94949D] dark:text-[#7d7d7d] dark:group-hover:text-[#94949D]  text-2xl" />
          </div>
        </button>
      )}
      {!props.loading
      ? <div className="flex gap-2">
      <div className="w-auto h-auto flex justify-center  items-center">
        <img
          className="w-[40px] h-[40px] object-cover rounded-full dark:shadow-sm dark:shadow-[#eeeeee]"
          src={props.dataAssistant?.image}
          alt="Logo"
        />
      </div>
      <div className="flex flex-col justify-center items-start ">
        <h2 className="font-semi-bold line-clamp-1 text-ellipsis  overflow-hidden whitespace-normal text-bunker-50 dark:text-black text-sm">
        {props.dataAssistant?.role}
        </h2>
        <p className="text-muted-foreground font-normal text-xs truncate text-[#9999A3]">
          Por @EnglishCode
        </p>
      </div>
    </div>
    : <div className="flex gap-2">
    <div className="w-auto h-auto flex justify-center  items-center">
      <div className="skeleton bg-[#26272B] w-[40px] h-[40px] rounded-full"></div>
    </div>
    <div className="flex flex-col justify-center items-start ">
      <div className="skeleton bg-[#26272B] w-20 h-3"></div>
      <div className="skeleton bg-[#26272B] w-10 h-3"></div>
    </div>
  </div>
      }
      <div className="flex items-center gap-2">
        <Theme/>
        {/* <button
        onClick={()=>{
          window.localStorage.removeItem("thread_id")
        }}
          className="hidden group p-2 rounded-full border-[1px] border-[#29292E] dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#F9F9F9] custom-transition md:flex justify-center items-center  "
        >
          <GiSoundWaves className="text-[#fafafa] text-xl dark:text-[#7d7d7d] " />
        </button> */}
        {!props.isSideOpen2 && 
        <button
        onClick={showSideRight}
        className="group p-2 rounded-full border-[1px] border-[#29292E] dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#F9F9F9] custom-transition flex justify-center items-center  cursor-pointer"
      >
        <BsThreeDots className="text-[#fafafa] text-lg dark:text-[#7d7d7d] " />
      </button>
        }
      </div>
    </header>
  );
};

export default Header;

import React, { useState } from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import QA from "../../assets/img/qa.png";
import { Link } from 'react-router-dom';

const CardSection1HomeAssistant = ({data}) => {

  const [gradientPosition, setGradientPosition] = useState({ x: 50, y: 50 });


  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setGradientPosition({ x, y });
  };

  return (
    <Link to={`/chat/assistant/${data.assistant_id}`} style={{
      background: `radial-gradient(circle at ${gradientPosition.x}% ${gradientPosition.y}%, #124268, #040e17)`,
      transition: 'background 10s',
    }} onMouseMove={handleMouseMove}  className="card cursor-pointer group p-4 flex flex-col items-start justify-between  w-[280px] h-[280px]">
            <div className="flex flex-col gap-2">
              <div className="w-full flex justify-start items-start gap-2">
                <img
                  src={data.image}
                  alt=""
                  className="w-[48px] h-[48px] rounded-full object-cover"
                />
                <p className="text-bunker-50 dark:text-[#7d7d7d] text-base font-semibold">
                {data.name}
                </p>
              </div>
              <div>
                <p className="text-bunker-50 dark:text-[#7d7d7d] text-base line-clamp-3">
                {data.description}
                </p>
              </div>
            </div>
            <div className="w-full flex justify-end items-center">
              <FaArrowRightLong className="text-bunker-50 dark:text-[#7d7d7d] mr-5 group-hover:mr-0 custom-transition" />
            </div>
          </Link>
  )
}

export default CardSection1HomeAssistant
